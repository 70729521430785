export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const peerPrefix = "paulwrubel-dev-peerpong-";

export const lobbyInfoMessageType = "peerjswrapper_lobby-info";

export const fontFamilyString = '"Source Code Pro", monospace';

// export const onError = (err: Error) => {
//     setError(err);
//     setConnectionState("error");
// };
